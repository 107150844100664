// import img
import servicesBanner from '../images/banner/servicesBanner.jpg';
import servicesBanner2x from '../images/banner/servicesBanner-2x.jpg';
import logoAngie from '../images/AG_logo_wword.png';
import logoAngie2x from '../images/AG_logo_wword-2x.png';
import logoAzerpost from '../images/logo_azerpost.png';
import logoAzerpost2x from '../images/logo_azerpost-2x.png';
import logoUzbPost from '../images/logo_uzb_post.png';
import logoUzbPost2x from '../images/logo_uzb_post-2x.png';
import logoAir from '../images/logo_mswair.png';
import logoAir2x from '../images/logo_mswair-2x.png';
import logoExpress from '../images/logo_express.png';
import logoExpress2x from '../images/logo_express-2x.png';

// import css
import '../theme/default/agel/base.scss';
import '../theme/default/agel/services.scss';

// import js
import React from 'react';
import Base from "../components/base";
import {useI18next} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import ExternalUrl from "../components/external_url";

interface ImageSrc {
    src1x: string,
    src2x: string,
    url?: string,
}

interface ServiceData {
    tableClassName: string,
    images?: ImageSrc[],
    logoText?: string,
    desc: string,
    content: string,
}

const servicesArr: ServiceData[] = [
    {
        tableClassName: 'angie',
        images: [{src1x: logoAngie, src2x: logoAngie2x}],
        desc: 'services:services.angie.desc',
        content: 'services:services.angie.content'
    },
    {
        tableClassName: 'azerpost',
        images: [
            {src1x: logoAzerpost, src2x: logoAzerpost2x},
            {src1x: logoUzbPost, src2x: logoUzbPost2x}
        ],
        desc: 'services:services.azerpost.desc',
        content: 'services:services.azerpost.content'
    },
    {
        tableClassName: 'air',
        images: [{src1x: logoAir, src2x: logoAir2x, url: 'https://www.mswair.com/'}],
        desc: 'services:services.air.desc',
        content: 'services:services.air.content'
    },
    {
        tableClassName: 'express',
        images: [{src1x: logoExpress, src2x: logoExpress2x, url: 'https://ship.msw.express/'}],
        desc: 'services:services.express.desc',
        content: 'services:services.express.content'
    },
    {
        tableClassName: 'tower',
        logoText: 'Angie Tower',
        desc: 'services:services.tower.desc',
        content: 'services:services.tower.content'
    }
];

function ProductAndServices() {
    const i18n = useI18next(['services', 'translation']);
    const {t} = i18n;

    return (
        <Base title={t('services:title')} t={t} i18n={i18n}>
            <main className={'products-services'}>
                <div className={'main-banner'}>
                    <img src={servicesBanner} srcSet={servicesBanner2x + " 2x"}/>
                </div>
                <h1>{t('services:title')}</h1>
                <ul className={'cnt'}>
                    {servicesArr.map((s, idx) => <li key={idx} className={s.tableClassName + ' service-li'}>
                        <div className={'service-th'}>{s.images ? s.images.length > 1 ?
                                <div className={'images'}>{s.images.map((i, idx) => {
                                    let img = <img key={idx} src={i.src1x}
                                                   srcSet={i.src2x + ' 2x'}/>;
                                    if (i.url) img = <ExternalUrl href={i.url} rel={'external'}>{img}</ExternalUrl>;
                                    return img
                                })}</div> :
                                (s.images[0].url ?
                                    <ExternalUrl href={s.images[0].url} rel={'external'}>
                                        <img src={s.images[0].src1x}
                                             srcSet={s.images[0].src2x + ' 2x'}/>
                                    </ExternalUrl> :
                                    <img src={s.images[0].src1x} srcSet={s.images[0].src2x + ' 2x'}/>) :
                            <h3>{s.logoText}</h3>}
                            <p dangerouslySetInnerHTML={{__html: t(s.desc)}}/></div>
                        <div className={'service-td'} dangerouslySetInnerHTML={{__html: t(s.content)}}/>
                    </li>)}
                </ul>
            </main>
        </Base>
    )
}

export default ProductAndServices;

export const data = graphql`
    query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["translation", "services"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;